import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
* {
  font-family: 'Kanit', sans-serif;
}

body {
  background-image: url('images/9.png');
 background-attachment: fixed;
background-position: left top;
background-repeat: no-repeat;
background-size: cover;

   }

@media only screen and (max-width: 1350px) {
body {
  background-image: url('images/5.png');
  background-attachment: fixed;
  background-position: top;
  background-size: 100%;
  background-repeat: repeat;
}
}



`

export default GlobalStyle
