import { MenuEntry, menuStatus } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://www.vizslaswap.com',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    items: [
      {
        label: 'Exchange',
        href: '/swap',
      },
      {
        label: 'Liquidity',
        href: '/pool',
      },
    ],
  },
  {
    label: ('Liquidity Mining'),
    icon: 'FarmIcon',
    href: 'https://www.vizslaswap.com/liquiditymining',
  },
  {
    label: ('Staking Pools'),
    icon: 'PoolIcon',
    href: 'https://www.vizslaswap.com/pools/all',
  },  
  {
    label: ('Lottery'),
    icon: 'TicketIcon',
    href: 'https://lottery.cryptochiptoken.com/',
    status: {
      text: ('Win').toLocaleUpperCase(),
      color: 'success',
    },
  },
  {
    label: ('Partnership'),
    icon: 'NftIcon',
        href: 'https://www.vizslaswap.com/partnership' ,
      },
  {
    label: ('Whitepaper'),
    icon: 'MoreIcon',
        href: 'https://www.vizslaswap.com/whitepaper' ,
      },
   
]

export default config
